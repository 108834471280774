import axios from 'axios'

export default {
  actions: {

    async getCity({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/cities/list/?start=' + start + '&num=' + limit, {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getCityId({commit}, idCity) {
      var result;
      try {
        return await axios
          .get("/api/cities/get/" + idCity + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async postFiltersCity({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/cities/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async addCity({commit}, data) {
      try {
        return await axios
          ('/api/cities/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async updateCity({commit}, data) {
      try {
        return await axios
          ('/api/cities/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },







    async postFiltersCountry({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/countries/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getCountryId({commit}, idCity) {
      var result;
      try {
        return await axios
          .get("/api/countries/get/" + idCity + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


  },
}