import axios from 'axios'

export default {
  actions: {
    async getCurrentUser() {
      var result;
      try {
        return await axios
          .get('/api/team_users/auth_info/', {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },



    async getCurrentUserStatistics() {
      var result;
      try {
        return await axios
          .get('/api/team_users/auth_info/stats/', {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  }
}