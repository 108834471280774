import store from '../store';

export default function dateFilter(value, format = "date") {
    const options = {};
    if (format.includes("date")) {
        options.day = "2-digit";

        options.month = "2-digit";
        options.year = "numeric";
    }
    if (format.includes("time")) {
        options.hour = "2-digit";
        options.minute = "2-digit";
        options.second = "2-digit";
    }

    const locale = 'ru';
    if(value == '0000-00-00 00:00:00' || value == null || value == '0000-00-00' || value == ''){
        return '';
    } else{
        return new Intl.DateTimeFormat(locale, options).format(new Date(value.replace(/-/g, "/")));
    }
    
}