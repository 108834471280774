import axios from 'axios';

export default {
  actions: {


    async postFiltersCalendar({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/calendar/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


  }
}