import axios from 'axios'

export default {
  actions: {

    async getQuotations({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/quotations/list/?start=' + start + '&num=' + limit, {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getQuotationsId({commit}, idQuotations) {
      var result;
      try {
        return await axios
          .get("/api/quotations/get/" + idQuotations + "/", {

          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getStatusQ({commit}) {
      var result;
      try {
        return await axios
          .get('/api/quotations_statuses/list/', {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },






    async postFiltersQuotations({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/quotations/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async updateDirectionsQuotations({commit}, updateDirections) {
      var result;
      try {
        return await axios
          ('/api/rio/quotations/directions/update/', {
            method: 'POST',
            data: updateDirections
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createDirectionsQuotations({commit}, createDirections) {
      var result;
      try {
        return await axios
          ('/api/rio/quotations/directions/add/', {
            method: 'POST',
            data: createDirections
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    async updateQuotations({commit}, updateQuotations) {
      var result;
      try {
        return await axios
          ('/api/quotations/update/', {
            method: 'POST',
            data: updateQuotations
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    async createQuotations({commit}, createQuotations) {
      var result;
      try {
        return await axios
          ('/api/quotations/add/', {
            method: 'POST',
            data: createQuotations
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  }
}