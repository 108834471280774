import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import titlePlugin from '@/plugins/meta.js'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import dateFilteer from '@/filters/date.filters'
import VCalendar from 'v-calendar'
import AutoReload from '@/plugins/AutoReload';
import VueTheMask from 'vue-the-mask';
import lang from '@/lang/lang';



import Axios from 'axios'

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Axios.defaults.headers.common['User-Auth-Key'] = token
} else {
  delete Axios.defaults.headers.common['User-Auth-Key']
}
Vue.prototype.Globalfilters = function(data){
  console.log('глобальная функция. Вызыввать this.Globalfilters');
}


Vue.use(titlePlugin)
Vue.filter('date', dateFilteer)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(VueTheMask)
Vue.use(lang)

Vue.use(VCalendar, {
  componentPrefix: 'v',
});



var langText = lang('ru');
var langActive = localStorage.getItem('lang');

let app = new Vue({
  router,
  store,

  data: {
    lang: langActive,
    langText: langText,
    app: '',
    add: {
      can_add_city: false,
      can_add_client: false,
      can_add_contractor: false,
      can_add_driver: false,
      can_add_lead: false,
      can_add_order: false,
      can_add_quote: false,
      can_add_team_user: false,
      can_add_user: false,
      is_dispatcher: 0,
    },
    edit: {
      can_edit_city: false,
      can_edit_client: false,
      can_edit_contractor: false,
      can_edit_driver: false,
      can_view_quote: false,
      can_edit_lead: false,
      can_edite_data_orders: false,
    },
    quote_id: '',
    currencyName: '',
    services_tied_containers: false,
    currencyIcc: '',
    dataFilling: [],
    doubleClick: {
      date: '',
      click: '0'
    },
    titleName: '',
    title: [{
      title: '', info: ''
    }],
    data: {
      isAuto: true,
      isFraght: false,
      order: {
        info: '',
        customer: '',
        carrier: ''
      },
      client: '',
      counterparties: {
        info: '',
        detail: {}
      },
      leads: '',
    },
    overflowApp: {
      flag: false
    },
    // client: {
    //   clientEdit: true,
    //   clientDelete: true,
    //   isread: true
    // },
    status: {
      client: '',
      order: '',
      quotation: '',
      lead: ''
    },
    type: '',
    view: '',
    typeMBL: '',
  },
  created() {
    var app = this
    var setInt = setInterval(function () {
      app.langText = lang(localStorage.getItem('lang'));
      app.lang = localStorage.getItem('lang');
    }, 1000);
    Vue.use(AutoReload, {
      config: {
        // модуль включен
        Enabled: true,
        // ежеминутное обновление
        CheckInterval: 60,
      },
      router: this.$router,
      vm: this,
    });
  },

  render: h => h(App)
}).$mount('#app')
