import axios from 'axios'

export default {
  actions: {

    async getTemplateLoading({commit}, {params: {start, limit}, data}) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_loading_templates/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async addTemplateLoading({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_loading_templates/add/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async updateTemplateLoading({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_loading_templates/update/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async deleteTemplateLoading({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_loading_templates/delete/?id=' + data, {
            method: 'POST',
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },















    async getTemplateUnloading({commit}, {params: {start, limit}, data}) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_unloading_templates/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async addTemplateUnloading({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_unloading_templates/add/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async updateTemplateUnloading({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_unloading_templates/update/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async deleteTemplateUnloading({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/rio/clients_unloading_templates/delete/?id=' + data, {
            method: 'POST',
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  }
}