import axios from 'axios'

export default {
  actions: {

    async getContactsClients({commit}, idClients) {
      var result;
      try {
        return await axios
          .get("/api/contacts/list/" + idClients + "/", {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    async updateContacts({commit}, updateContacts) {
      var result;
      try {
        return await axios
          ('/api/contacts/update/', {
            method: 'POST',
            data: updateContacts
            
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },



    async addContacts({commit}, addContacts) {
      var result;
      try {
        return await axios
          ("/api/contacts/add/", {
            method: 'POST',
            data: addContacts
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async updateTeam_users({commit}, updateTeam_users) {
      var result;
      try {
        return await axios
          ('/api/team_users/update/', {
            method: 'POST',
            data: updateTeam_users
          })
          .then(resp => {
            console.log(resp)
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    async updateContactsPerson({commit}, updateContactsPerson) {
      var result;
      try {
        return await axios
          ('/api/contact_persons/update/', {
            method: 'POST',
            data: updateContactsPerson
            
          })
          .then(resp => {
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },



    async addContactsPerson({commit}, addContactsPerson) {
      try {
        return await axios
          ("/api/contact_persons/add/", {
            method: 'POST',
            data: addContactsPerson
          })
          .then(resp => {
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async deleteContacts({commit}, id) {
      var result;
      try {
        return await axios
          .get("/api/contacts/delete/" + id + "/", {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    //type contacts

    async getContactsType({commit}) {
      var result;
      try {
        return await axios
          .get("/api/contacts_types/list/", {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    








    //Список контактных лиц
    async getContactsPersons({commit}, {id, type, data}) {
      var result;
      try {
        return await axios
          ("/api/contact_persons/list/?id=" + id + "&type=" + type, {
            method: 'POST',
            data: {filters: data}
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


  }
}