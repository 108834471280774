<template>
  <div class="other" :class="{'menu-open': $root.overflowApp.flag}" >
    <Menu @click="isOpen = !isOpen" v-model="isOpen" />
    <Title v-model="isOpen" @click="isOpen = !isOpen" />
    <transition :name="transitionName" mode="out-in" appear>
      <router-view />
    </transition>
    <Toolbar />
    <audio controls="controls" style="display: none" src="https://crm-core.soft-pro.com/img/new_bell.mp3" id="myAudioCall"></audio>
  </div>
</template>


<style lang="scss">
  .other{
    overflow: hidden;
    width: 100vw;
  }
  .slide-right-enter-active, .slide-right-leave-active {
    transition: all .5s;
    // position: relative;
    width: 100%;
    // left: 0;
    z-index: 9;
  }
  .slide-right-enter, .slide-right-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    // left: 100%;
  }
  .slide-left-enter-active, .slide-left-leave-active {
    transition: all .5s;
    // position: relative;
    width: 100%;
    // left: 0;
  }
  .slide-left-enter, .slide-left-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    // left: -100%;
  }
</style>



<script>
import Menu from '@/components/app/Menu'
import Title from '@/components/app/Title'
import Toolbar from '@/components/app/Toolbar'
export default {
  name: 'home-layout',
  data: () => ({
    isOpen: false,
    transitionName: '',
    setinerval: '',
  }),




  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    },
  },

  async created() {

    
  },




  async mounted() {
    var res = await this.$store.dispatch('getCurrentUser');

    if(res){
      var app = this
      setTimeout(function(){

        app.$root.add.can_add_city = res.data.detail.can_add_city ? res.data.detail.can_add_city : false
        app.$root.add.can_add_client = res.data.detail.can_add_client ? res.data.detail.can_add_client : false
        app.$root.add.can_add_contractor = res.data.detail.can_add_contractor ? res.data.detail.can_add_contractor : false
        app.$root.add.can_add_driver = res.data.detail.can_add_driver ? res.data.detail.can_add_driver : false
        app.$root.add.can_add_lead = res.data.detail.can_add_lead ? res.data.detail.can_add_lead : false
        app.$root.add.can_add_order = res.data.detail.can_add_order ? res.data.detail.can_add_order : false
        app.$root.add.can_add_quote = res.data.detail.can_add_quote ? res.data.detail.can_add_quote : false
        app.$root.add.can_add_team_user = res.data.detail.can_add_team_user ? res.data.detail.can_add_team_user : false
        app.$root.add.can_add_user = res.data.detail.can_add_user ? res.data.detail.can_add_user : false

        if(app.$root.app == 'SoftProCrmAppRioTransTest' || app.$root.app == 'SoftProCrmAppRioTrans'){
          app.$root.add.is_dispatcher = res.data.detail.is_dispatcher == 0 ? false : true;
          app.$root.edit.can_edite_data_orders = res.data.detail.can_edit_date_create_doc ? res.data.detail.can_edit_date_create_doc : false
        } else{
          app.$root.add.is_dispatcher = false;
        }

        app.$root.edit.can_edit_city = res.data.detail.can_edit_city ? res.data.detail.can_edit_city : false
        app.$root.edit.can_edit_client = res.data.detail.can_edit_client ? res.data.detail.can_edit_client : false
        app.$root.edit.can_edit_contractor = res.data.detail.can_edit_contractor ? res.data.detail.can_edit_contractor : false
        app.$root.edit.can_edit_driver = res.data.detail.can_edit_driver ? res.data.detail.can_edit_driver : false
        app.$root.edit.can_edit_lead = res.data.detail.can_edit_lead ? res.data.detail.can_edit_lead : false
        app.$root.edit.can_view_quote = res.data.detail.can_view_quote ? res.data.detail.can_view_quote : false
        app.$root.edit.can_edit_plan_calculation = res.data.detail.can_edit_plan_calculation ? res.data.detail.can_edit_plan_calculation : false
        app.$root.edit.can_view_plan_calculation = res.data.detail.can_view_plan_calculation ? res.data.detail.can_view_plan_calculation : false
        app.$root.edit.can_view_sum_income = res.data.detail.can_view_sum_income ? res.data.detail.can_view_sum_income : false
        window.crmUserID = res.data.detail.id
      }, 500);
    }




    var date = Math.round(new Date().getTime() / 1000)
    this.setinerval = window.setInterval(async () => {
      var data = {
        action: 'main',
        date_from: date
      }

      var notification = await this.$store.dispatch('getNatification', data)
      date = Math.round(new Date().getTime() / 1000);
      if(notification){
        if(notification.data.has_new_messages){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationMess', true);
        }
        if(notification.data.has_new_orders){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationOrder', true);
        }
        if(notification.data.has_new_quotes){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationQuotes', true);
        } 
        if(notification.data.has_new_leads){
            document.getElementById('myAudioCall').play().then(val => {}).catch(error => {
              console.log("Autoplay Not started!");
            });
          localStorage.setItem('notificationLeads', true);
        }
      }
      
    },3000);
    


    
  },


  beforeDestroy() {
    clearInterval(this.setinerval)
    window.crmUserID = ''
  },




  components: {
    Menu, Title, Toolbar
  }
}
</script>