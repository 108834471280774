import axios from 'axios'

export default {
  actions: {

		async getOrg({commit}, { params: {start, limit}, data }) {
			var result;
			try {
				return await axios
					('/api/organizations/list/?start=' + start + '&num=' + limit, {
						method: 'POST',
						data: {filters: data}
					})
					.then(response => (result = response))
					.catch(error => console.log(error));
			} catch (e) {
					console.log(e);
					throw e;
			}
		},


		async getlines({commit}, {params: {start, limit}, data}) {
			var result;
			try {
				return await axios
					('/api/lines/list/?start=' + start + '&num=' + limit, {
						method: 'POST',
						data: {filters: data}
					})
					.then(response => (result = response))
					.catch(error => console.log(error));
			} catch (e) {
					console.log(e);
					throw e;
			}
		},


		async courtAction({commit}, {params: {start, limit}, data}) {
			try {
				return await axios
					('/api/vessels/list/?start=' + start + '&num=' + limit, {
						
						method: 'POST',
						data: {filters: data}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},


		async carModels({commit}, {params: {start, limit}, data}) {
			try {
				return await axios
					('/api/car_models/list/?start=' + start + '&num=' + limit, {
						
						method: 'POST',
						data: {filters: data}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},


		async carManufacturers({commit}, {params: {start, limit}, data}) {
			try {
				return await axios
					('/api/car_manufacturers/list/?start=' + start + '&num=' + limit, {
						
						method: 'POST',
						data: {filters: data}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},


		async servicesName({commit}, {params: {start, limit}, data}) {
			try {
				return await axios
					('/api/services/list/?start=' + start + '&num=' + limit, {
						
						method: 'POST',
						data: {filters: data}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},

		async incomeNDS({commit}, {params: {start, limit}, data}) {
			try {
				return await axios
					('/api/vat/list/?start=' + start + '&num=' + limit, {
						
						method: 'POST',
						data: {filters: data}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},

		async paymentTypes({commit}, {params: {start, limit}, data}) {
			try {
				return await axios
					('/api/payment_types/list/?start=' + start + '&num=' + limit, {
						
						method: 'POST',
						data: {filters: data}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},


		async contractorsTransport({commit}, {filter, id}) {
			try {
				return await axios
					('/api/contractors_transport_companies/list/?contractor_id='+id, {
						
						method: 'POST',
						data: {filters: filter}
					})
					.then(resp => {
						return resp
					})
					.catch(err => {
						console.log(err)
					})
			} catch (e) {
					console.log(e);
					throw e;
			}
		},



  }
}