import axios from 'axios'

export default {
  actions: {

    async getClients({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/clients/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getClientsId({commit}, idClients) {
      var result;
      try {
        return await axios
          .get("/api/clients/get/" + idClients + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async getClientsOrganizations({commit}) {
      var result;
      try {
        return await axios
          .get('/api/organizations/list/', {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async getClientsStatus({commit}) {
      var result;
      try {
        return await axios
          .get('/api/clients_statuses/list/', {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    






    async postFiltersClients({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/clients/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    async updateClients({commit}, updateClients) {
      var result;
      try {
        return await axios
          ('/api/clients/update/', {
            
            method: 'POST',
            data: updateClients
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    async createClients({commit}, createClients) {
      return await axios
        ('/api/clients/add/', {
          method: 'POST',
          data: createClients
        })
        .then(resp => {
          // console.log(resp)
          return resp
          // resolve(resp)
        })
        .catch(err => {
          console.log(err)
          // reject(err)
        })
    },






    async getClientsSourceId({commit}, id) {
      var result;
      try {
        return await axios
          .get("/api/clients_src_groups/get/" + id + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getClientsSource({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/clients_src_groups/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersClientsSource({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/clients_src_groups/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


  }
}