import axios from 'axios';

export default {
  actions: {
    async getOrders({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/orders/list/?start=' + start + '&num=' + limit, {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getStatus({commit}) {
      var result;
      try {
        return await axios
          .get('/api/order_statuses/list/', {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getOrdersId({commit}, idOrders) {
      var result;
      try {
        return await axios
          .get("/api/orders/get/" + idOrders + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },







    async postFiltersOrders({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/orders/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async exchangeRate({commit}, {date, currency2}) {
      try {
        return await axios
          .get('/api/currencies/exchange_rate/?date=' + date + '&currency=' + currency2, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async orderType({commit}) {
      try {
        return await axios
          .get('/api/orders_dir_types/list/', {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    async updateOrders({commit}, updateOrders) {
      var result;
      try {
        return await axios
          ('/api/orders/update/', {
            method: 'POST',
            data: updateOrders
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async updateDirections({commit}, updateDirections) {
      var result;
      try {
        return await axios
          ('/api/rio/orders/directions/update/', {
            method: 'POST',
            data: updateDirections
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createDirections({commit}, createDirections) {
      var result;
      try {
        return await axios
          ('/api/rio/orders/directions/add/', {
            method: 'POST',
            data: createDirections
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async updateDirectionsQ({commit}, updateDirections) {
      var result;
      try {
        return await axios
          ('/api/rio/quotations/directions/update/', {
            method: 'POST',
            data: updateDirections
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createDirectionsQ({commit}, createDirections) {
      var result;
      try {
        return await axios
          ('/api/rio/quotations/directions/add/', {
            method: 'POST',
            data: createDirections
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async deleteDirections({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/directions/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    async createOrders({commit}, createOrders) {
      var result;
      try {
        return await axios
          ('/api/orders/add/', {
            method: 'POST',
            data: createOrders
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },







    async getOrdersTypes({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/order_types/list/?start=' + start + '&num=' + limit, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },






    async getCurrency({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/currencies/list/?start=' + start + '&num=' + limit, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },















    async updateCustomerFreight({commit}, {data, mod}) {
      try {
        return await axios
          ('/api/rio/'+ mod +'/customer_freight/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },



    async deleteCustomerFreight({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/customer_freight/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },



    async updateCarrierFreight({commit}, {data, mod}) {
      
      try {
        return await axios
          ('/api/rio/'+ mod +'/carrier_freight/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async deleteCarrierFreight({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/carrier_freight/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async updateCarrierFreight2({commit}, {data, mod}) {
      try {
        return await axios
          ('/api/rio/'+ mod +'/carrier_freight2/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    
    async deleteCarrierFreight2({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/carrier_freight2/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },










    async createCustomerFreight({commit}, {data, mod}) {
      var result;
      try {
        return await axios
          ('/api/rio/'+ mod +'/customer_freight/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createCarrierFreight({commit}, {data, mod}) {
      var result;
      try {
        return await axios
          ('/api/rio/'+ mod +'/carrier_freight/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createCarrierFreight2({commit}, {data, mod}) {
      var result;
      try {
        return await axios
          ('/api/rio/'+ mod +'/carrier_freight2/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },












    async updateKickbacks({commit}, {data, mod}) {
      try {
        return await axios
          ('/api/rio/'+ mod +'/kickbacks_customer/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async deleteKickbacks({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/kickbacks_customer/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async updateCarrierKickbacks({commit}, {data, mod}) {
      try {
        return await axios
          ('/api/rio/'+ mod +'/kickbacks_carrier/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async deleteCarrierKickbacks({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/kickbacks_carrier/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async updateCarrierKickbacks2({commit}, {data, mod}) {
      try {
        return await axios
          ('/api/rio/'+ mod +'/kickbacks_carrier2/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async deleteCarrierKickbacks2({commit}, {mod, id}) {
      var result;
      try {
        return await axios
          ('/api/rio/' + mod +  '/kickbacks_carrier2/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },










    async createKickbacks({commit}, {data, mod}) {
      var result;
      try {
        return await axios
          ('/api/rio/'+ mod +'/kickbacks_customer/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createCarrierKickbacks({commit}, {data, mod}) {
      var result;
      try {
        return await axios
          ('/api/rio/'+ mod +'/kickbacks_carrier/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async createCarrierKickbacks2({commit}, {data, mod}) {
      var result;
      try {
        return await axios
          ('/api/rio/'+ mod +'/kickbacks_carrier2/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

















    async applicationList({commit}, {type, order, quote}) {
      try {
        return await axios
          ('/api/rio/requests_from_quotes/list/?type=' + type + '&order=' + order + '&quote=' + quote, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async typeMBL({commit}) {
      try {
        return await axios
          .get('/api/bl_types/list/', {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async updateRequests({commit}, updateRequests) {
      try {
        return await axios
          ('/api/rio/requests_from_quotes/update/', {
            method: 'POST',
            data: updateRequests
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async createRequests({commit}, createRequests) {
      var result;
      try {
        return await axios
          ('/api/rio/requests_from_quotes/add/', {
            method: 'POST',
            data: createRequests
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async deleteRequests({commit}, id) {
      var result;
      try {
        return await axios
          ('/api/rio/requests_from_quotes/delete/?id=' + id, {
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    
  }
}