/* eslint-disable no-console */

import { register } from 'register-service-worker'

navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(function(worker){
  // worker.active.postMessage(`${window.update}`);
  var interv = setInterval(function(){
    if(window.update){
      worker.active.postMessage(`${window.update}`);
    }
    window.clearInterval(interv)
  }, 700);
})

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.');
      // registration.waiting.postMessage({ action: "skipWaiting" });
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      registration.waiting.postMessage({ action: `${window.update}` });
      window.update = true
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  });

 }
