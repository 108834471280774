<template>
<div>
  <transition name="fade">
    <div 
      @click="closeMenu" 
      class="menu" 
      v-if="value" 
      v-bind:class="$route.name == 'Home' 
          || $route.name == 'Settings' 
          || $route.name == 'Statistics' ? 'menu' : 'menu menu--other'">
      <div class="menu__block">
        <div v-if="$root.app == 'SoftProCrmAppRioTransTest' || $root.app == 'SoftProCrmAppRioTrans'">
          <ul class="menu__block__btn" v-if="$route.name == 'ClientsCreateId'">
            <li>
              <a @click.prevent="openTemplates('download')">
                <div class="icon icon-directory"></div>
                {{$root.langText.menu.downloadTemplates}}
              </a>
            </li>
            <li>
              <a @click.prevent="openTemplates('unloading')">
                <div class="icon icon-directory"></div>
                {{$root.langText.menu.unloadingTemplates}}
              </a>
            </li>
            <!-- <li>
              <a href="#">
                <div class="icon icon-delete"></div>
                Видалити клієнта
              </a>
            </li>
            <li>
              <a href="#">
                <div class="icon icon-file"></div>
                Файли
              </a>
            </li>
            <li>
              <a href="#" v-if="$route.name == 'ClientsCreateId'">
                <div class="icon icon-history"></div>
                Історія змінення
              </a>
              <a href="#" v-else>
                <div class="icon icon-document"></div>
                Договори
              </a>
            </li> -->
            <li><div class="decor-line"></div></li>
          </ul>
        </div>
        <ul>
          <router-link 
            v-for="link in links"
            :key="link.url"
            :to="link.url"
            tag="li"
          >
            <a href="#" ><div v-bind:class="[link.img]"></div>{{link.title}}</a>
          </router-link>
        </ul>
        <div class="decor-line"></div>
        <ul>
          <li>
            <a href="#" @click.prevent="logout">
              <div class="icon icon-exit"></div>
              {{$root.langText.menu.exit}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </transition>
  <Templates v-if="popup.flag" :idClient="$route.params.id" :popup="popup" @update="updateTemplates"  @close="popup.flag = false"/>
  </div>
</template>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>

<script>
import Templates from "@/components/selection/Templates";


export default {
  props: ['value'],

  data: () => ({
    popup: {
      flag: false,
      mod: '',
      edit: true
    },


    links: [
      {title: 'Головна', url: '/', img: 'icon icon-home'},
      {title: 'Чати', url: '/chat', img: 'icon icon-chat'},
      {title: 'Котирування', url: '/quotes', img: 'icon icon-quotation'},
      {title: 'Угоди', url: '/orders', img: 'icon icon-agreements'},
      {title: 'Статистика', url: '/statistics', img: 'icon icon-statistics'},
      {title: 'Налаштування', url: '/settings', img: 'icon icon-tuning'},
    ],
  }),



  mounted() {
    var app = this
    setInterval(function(){
      if(app.$root.app == 'SoftProCrmAppRioTransTest' || app.$root.app == 'SoftProCrmAppRioTrans'){
        app.links[2].title = app.$root.langText.menu.quotes
        app.links[3].title = app.$root.langText.menu.order
      } else{
        app.links[2].title = app.$root.langText.menu.quotesOther
        app.links[3].title = app.$root.langText.menu.orderOther
      }
      app.links[0].title = app.$root.langText.menu.home
      app.links[1].title = app.$root.langText.menu.chat
      app.links[4].title = app.$root.langText.menu.statistics
      app.links[5].title = app.$root.langText.menu.settings
      if(app.$root.app == "SoftProCrmAppVedanta"){
        app.links.splice(2, 1)
        app.links.splice(2, 0, {title: app.$root.langText.menu.leads, url: '/leads', img: 'icon icon-directory'})
      }
    }, 2000);
  },



  methods: {


    openTemplates(mod){
      this.popup.flag = true;
      this.popup.mod = mod;
    },  

    updateTemplates(data){
      this.directions.this.popup.flag = false


      
      this.directions.downloadPoint = ""
      this.directions.downloadPoint_id = ""

      this.directions.addressDownload = ""

      this.directions.addressClearanceIn = ""

      this.directions.unloadingPoint = ""
      this.directions.unloadingPoint_id = ""

      this.directions.addressUnloading = ""

      this.directions.addressClearanceOut = ""

      this.directions.cargo = ""
    },


    async logout(){
      await this.$store.dispatch('logout')
      this.$router.push('/login')
    },

    closeMenu(){
      this.$emit('click')
      if(this.value){
        this.$root.overflowApp.flag = false
      }else{
        this.$root.overflowApp.flag = true
      }
      
    }
  },


  components:{
    Templates
  }
}
</script>