import axios from 'axios'

export default {
  actions: {


    async addFile({commit}, {id, type, name, data}) {
      var result;
      try {
        return await axios
          ('/api/files/add/?o_id='+ id + '&o_t='+ type + '&n='+ name, 
          {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  },  
}