import axios from 'axios'

export default {
  actions: {

    async getCarType({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/container_types/list/?start=' + start + '&num=' + limit, {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersCarType({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/container_types/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },









    async getDrivers({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/drivers/list/?start=' + start + '&num=' + limit, {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async addDrivers({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/drivers/add/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersDrivers({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/drivers/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async updateDrivers({commit}, data) {
      try {
        return await axios
          ('/api/drivers/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },







    async getCar({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/trucks/list/?start=' + start + '&num=' + limit, {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async addCar({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/trucks/add/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
    async updateCar({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/trucks/update/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersCar({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/trucks/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },
  
  







    async getTrailers({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/trailers/list/?start=' + start + '&num=' + limit, {
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async addTrailers({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/trailers/add/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async updateTrailers({commit}, data) {
      var result;
      try {
        return await axios
          ('/api/trailers/update/', {
            method: 'POST',
            data: data
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersTrailers({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/trailers/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    //плашки авто
    async carsUpdate({commit}, data) {
      try {
        return await axios
          ('/api/cars/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async carsAdd({commit}, data) {
      try {
        return await axios
          ('/api/cars/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async carsDelete({commit}, id) {
      try {
        return await axios
          ('/api/cars/delete/' + id + "/", {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },







    //плашки контейнеры
    async containersUpdate({commit}, data) {
      try {
        return await axios
          ('/api/orders/containers/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async containersAdd({commit}, data) {
      try {
        return await axios
          ('/api/orders/containers/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async containersDelete({commit}, id) {
      try {
        return await axios
          ('/api/orders/containers/delete/?id=' + id, {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    //плашки услуги сделок
    async servicesUpdate({commit}, data) {
      try {
        return await axios
          ('/api/orders/services/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async servicesAdd({commit}, data) {
      try {
        return await axios
          ('/api/orders/services/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async servicesDelete({commit}, id) {
      try {
        return await axios
          ('/api/orders/services/delete/?id=' + id, {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    //плашки услуги катировок
    async servicesUpdateQuotations({commit}, data) {
      try {
        return await axios
          ('/api/quotations/services/update/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async servicesAddQuotations({commit}, data) {
      try {
        return await axios
          ('/api/quotations/services/add/', {
            method: 'POST',
            data: data
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async servicesDeleteQuotations({commit}, id) {
      try {
        return await axios
          ('/api/quotations/services/delete/?id=' + id, {
          })
          .then(resp => {
            return resp
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },



    async conditions({commit}, {params: {start, limit}, data}) {
      var result;
      try {
        return await axios
          ('/api/incoterms/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async cargo({commit}, {params: {start, limit}, data}) {
      var result;
      try {
        return await axios
          ('/api/cargoes/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async route({commit}, {params: {start, limit}, data}) {
      var result;
      try {
        return await axios
          ('/api/routes/list/?start=' + start + '&num=' + limit, {
            method: 'POST',
            data: {filters: data}
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    
  }
}