<template>
  <ul class="nav-bar" @click="go">

    <router-link
      tag="li"
      active-class="active"
      v-for="(link, index) in links"
      :key="index"
      :to="link.url"
      :exact="link.exact"
    >
      <a href="#">
        <span v-if="link.flag == 'true'"></span>
        <div class="icon" :class="link.img"></div>
      </a>
    </router-link>


    <li></li>
  </ul>
</template>


<style lang="scss">
  .nav-bar{
    z-index: 6;
  }
  .icon-clients{
    background-image: url("data:image/svg+xml,%3Csvg id='Capa_1' enable-background='new 0 0 512 512' height='25' viewBox='0 0 512 512' width='25' fill='%238EB6E3' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ccircle cx='256' cy='119.631' r='87'/%3E%3Ccircle cx='432' cy='151.63' r='55'/%3E%3Ccircle cx='80' cy='151.63' r='55'/%3E%3Cpath d='m134.19 256.021c-21.65-17.738-41.257-15.39-66.29-15.39-37.44 0-67.9 30.28-67.9 67.49v109.21c0 16.16 13.19 29.3 29.41 29.3 70.026 0 61.59 1.267 61.59-3.02 0-77.386-9.166-134.137 43.19-187.59z'/%3E%3Cpath d='m279.81 241.03c-43.724-3.647-81.729.042-114.51 27.1-54.857 43.94-44.3 103.103-44.3 175.48 0 19.149 15.58 35.02 35.02 35.02 211.082 0 219.483 6.809 232-20.91 4.105-9.374 2.98-6.395 2.98-96.07 0-71.226-61.673-120.62-111.19-120.62z'/%3E%3Cpath d='m444.1 240.63c-25.17 0-44.669-2.324-66.29 15.39 51.965 53.056 43.19 105.935 43.19 187.59 0 4.314-7.003 3.02 60.54 3.02 16.8 0 30.46-13.61 30.46-30.34v-108.17c0-37.21-30.46-67.49-67.9-67.49z'/%3E%3C/g%3E%3C/svg%3E");
    width: 25px;
    height: 25px;
  }
  .icon-directory{
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 17.999H2C0.930516 18.035 0.0364164 17.0916 0 15.8887V2.11017C0.0364132 0.907463 0.930683 -0.0356059 2 0.00103238H18C19.0693 -0.0356059 19.9636 0.907463 20 2.11017V15.8899C19.963 17.0923 19.0691 18.035 18 17.999ZM2 2.25078V15.7369L18 15.7493V2.26315L2 2.25078ZM11.43 13.4995H4C4.07353 12.5682 4.46534 11.7052 5.093 11.0923C5.79183 10.3124 6.73081 9.86528 7.715 9.84367C8.69919 9.86528 9.63817 10.3124 10.337 11.0923C10.9645 11.7054 11.3563 12.5682 11.43 13.4995ZM16 12.3746H13V10.1249H16V12.3746ZM7.715 9.00002C7.17907 9.02097 6.65947 8.79067 6.28029 8.36414C5.9011 7.9376 5.69638 7.35312 5.715 6.75027C5.69668 6.14752 5.9015 5.56323 6.28062 5.13677C6.65974 4.71031 7.17916 4.47991 7.715 4.50052C8.25084 4.47991 8.77026 4.71031 9.14938 5.13677C9.5285 5.56323 9.73332 6.14752 9.715 6.75027C9.73362 7.35312 9.5289 7.9376 9.14971 8.36414C8.77053 8.79067 8.25093 9.02097 7.715 9.00002ZM16 7.87514H12V5.6254H16V7.87514Z' fill='%238EB6E3'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 18px;
  }
</style>


<script>
export default {
  data: () => ({
    links: [
      {url: '/quotes', img: 'icon-quotation', flag: localStorage.getItem('notificationQuotes')},
      {url: '/orders', img: 'icon-agreements', flag: localStorage.getItem('notificationOrder')},
      {url: '/', img: 'icon-home', exact: true},
      {url: '/chat', img: 'icon-chat', flag: localStorage.getItem('notificationMess')},
      {url: '/settings', img: 'icon-tuning'},
    ],
    setinerval: ''
  }),

  mounted() {
    var app = this
    if(this.$root.app !== 'SoftProCrmAppRioTransTest' && this.$root.app !== 'SoftProCrmAppRioTrans'){
      this.links.splice(4, 1)
      this.links.push({url: '/leads', img: 'icon-directory', flag: localStorage.getItem('notificationLeads')},)
    }

    this.setinerval = window.setInterval(async () => {
      app.links[0].flag = localStorage.getItem('notificationQuotes')
      app.links[1].flag = localStorage.getItem('notificationOrder')
      app.links[3].flag = localStorage.getItem('notificationMess')
      if(app.$root.app !== 'SoftProCrmAppRioTransTest' && app.$root.app !== 'SoftProCrmAppRioTrans'){
        app.links[4].flag = localStorage.getItem('notificationLeads')
      }
      if(this.$root.app == "SoftProCrmAppVedanta"){
        this.links.splice(4, 1)
        this.links.splice(3, 1)
        this.links.splice(0, 1)
        this.links.push({url: '/clients', img: 'icon-clients'},)
        this.links.push({url: '/chat', img: 'icon-chat', flag: localStorage.getItem('notificationMess')})
        this.links.unshift({url: '/leads', img: 'icon-directory', flag: localStorage.getItem('notificationLeads')},)
      }
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.setinerval)
  },



  methods: {
    go(){
      this.$root.overflowApp.flag = false
    }
  },
}
</script>