<template>
  <div>
    <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
    <div class="pop-up">
      <a
        class="pop-up__close icon icon-close"
        @click.prevent="$emit('close')"
      ></a>

      <!-- car -->
      <div v-if="data.modPopup == 'car'">
        <h2>{{$root.langText.input.car}}</h2>
        <div class="form__input">
          <input type="text" v-model="car.name" />
          <span>{{$root.langText.input.name}}</span>
        </div>
        <div
          class="form__input"
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
        >
          <input type="text" v-model="car.gov_number" />
          <span>{{$root.langText.input.stateNumber}}</span>
        </div>
        <div
          class="form__input"
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
        >
          <input type="text" v-model="car.year_manufacture" />
          <span>{{$root.langText.input.yearManufacture}}</span>
        </div>
        <div v-if="!data.contractor_id">
          <Autocomplete
            v-if="
              $root.app == 'SoftProCrmAppRioTransTest' ||
              $root.app == 'SoftProCrmAppRioTrans'
            "
            :data="{
              name: $root.langText.input.counterparty,
              filter: 'is_main',
              mod: 'counterparties',
              key: 'counterparties',
              input: car.counterparties,
              inputId: car.counterparties_id,
            }"
            @result="autocompleteResult"
          />
          <Autocomplete
            v-if="
              $root.app !== 'SoftProCrmAppRioTransTest' &&
              $root.app !== 'SoftProCrmAppRioTrans'
            "
            :data="{
              name: $root.langText.input.counterparty,
              mod: 'counterparties',
              key: 'counterparties',
              input: car.counterparties,
              inputId: car.counterparties_id,
            }"
            @result="autocompleteResult"
          />
        </div>
        <Autocomplete
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
          :data="{
            name: $root.langText.input.driver,
            mod: 'drivers',
            key: 'drivers',
            input: car.drivers,
            inputId: car.drivers_id,
          }"
          @result="autocompleteResult"
        />
        <Autocomplete
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
          :data="{
            name: $root.langText.input.trailer,
            mod: 'trailers',
            key: 'trailer',
            input: car.trailer,
            inputId: car.trailer_id,
          }"
          @result="autocompleteResult"
        />
      </div>

      <!-- trailers -->
      <div v-if="data.modPopup == 'trailers'">
        <h2>{{$root.langText.directory.trailers}}</h2>
        <div class="form__input">
          <input type="text" v-model="trailers.name" />
          <span>{{$root.langText.input.number}}</span>
        </div>
        <div v-if="!data.contractor_id">
          <Autocomplete
            v-if="
              $root.app == 'SoftProCrmAppRioTransTest' ||
              $root.app == 'SoftProCrmAppRioTrans'
            "
            :data="{
              name: $root.langText.input.counterparty,
              filter: 'is_main',
              mod: 'counterparties',
              key: 'counterparties',
              input: trailers.counterparties,
              inputId: trailers.counterparties_id,
            }"
            @result="autocompleteResult"
          />
          <Autocomplete
            v-if="
              $root.app !== 'SoftProCrmAppRioTransTest' &&
              $root.app !== 'SoftProCrmAppRioTrans'
            "
            :data="{
              name: $root.langText.input.counterparty,
              mod: 'counterparties',
              key: 'counterparties',
              input: trailers.counterparties,
              inputId: trailers.counterparties_id,
            }"
            @result="autocompleteResult"
          />
        </div>
        <Autocomplete
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
          :data="{
            name: $root.langText.input.driver,
            mod: 'drivers',
            key: 'drivers',
            input: trailers.drivers,
            inputId: trailers.drivers_id,
          }"
          @result="autocompleteResult"
        />
      </div>

      <!-- drivers -->
      <div v-if="data.modPopup == 'drivers'">
        <h2>{{$root.langText.directory.drivers}}</h2>
        <div class="form__input">
          <input type="text" v-model="drivers.name" />
          <span>{{$root.langText.input.firstName}}</span>
        </div>
        <div class="form__input">
          <input type="text" v-model="drivers.phone" />
          <span>{{$root.langText.input.phone}}</span>
        </div>
        <div
          class="form__input"
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
        >
          <p class="form__input__textarea-p">{{ drivers.comment }}</p>
          <textarea class="form__input__textarea" v-model="drivers.comment" />
          <span>{{$root.langText.input.comments}}</span>
        </div>
        <div v-if="!data.contractor_id">
          <Autocomplete
            v-if="
              $root.app == 'SoftProCrmAppRioTransTest' ||
              $root.app == 'SoftProCrmAppRioTrans'
            "
            :data="{
              name: $root.langText.input.counterparty,
              filter: 'is_main',
              mod: 'counterparties',
              key: 'counterparties',
              input: drivers.counterparties,
              inputId: drivers.counterparties_id,
            }"
            @result="autocompleteResult"
          />
          <Autocomplete
            v-if="
              $root.app !== 'SoftProCrmAppRioTransTest' &&
              $root.app !== 'SoftProCrmAppRioTrans'
            "
            :data="{
              name: $root.langText.input.counterparty,
              mod: 'counterparties',
              key: 'counterparties',
              input: drivers.counterparties,
              inputId: drivers.counterparties_id,
            }"
            @result="autocompleteResult"
          />
        </div>
        <Autocomplete
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
          :data="{
            name: $root.langText.input.car,
            mod: 'car',
            key: 'car',
            input: drivers.car,
            inputId: drivers.car_id,
          }"
          @result="autocompleteResult"
        />
        <Autocomplete
          v-if="
            $root.app !== 'SoftProCrmAppRioTransTest' &&
            $root.app !== 'SoftProCrmAppRioTrans'
          "
          :data="{
            name: $root.langText.input.trailer,
            mod: 'trailers',
            key: 'trailer',
            input: drivers.trailer,
            inputId: drivers.trailer_id,
          }"
          @result="autocompleteResult"
        />
      </div>

      <!-- counterparties -->
      <div v-if="data.modPopup == 'counterparties'">
        <h2>{{$root.langText.input.counterparty}}</h2>
        <div class="form__input">
          <input type="text" v-model="counterparties.name" />
          <span>{{$root.langText.input.name}}</span>
        </div>
        <div class="form__row form__row-50">
          <div class="form__radio">
            <label>
              <input
                type="radio"
                name="people"
                value="2"
                v-model="counterparties.people"
              />
              <span>{{$root.langText.input.individual}}</span>
            </label>
          </div>
          <div class="form__radio">
            <label>
              <input
                type="radio"
                name="people"
                value="1"
                v-model="counterparties.people"
              />
              <span>{{$root.langText.input.legalEntity}}</span>
            </label>
          </div>
        </div>
      </div>

      <!-- clients -->
      <div class="handbook_checkbox" v-if="data.modPopup == 'clients'">
				<h2>{{$root.langText.input.client}}</h2>
				<div style="max-height: 74vh;overflow: scroll;">
					<div class="form__input">
						<input type="text" v-model="fields.name" />
						<span>{{$root.langText.input.name}}</span>
					</div>

					<div class="form__input">
						<input type="text" v-model="fields.nameFull" />
						<span>{{$root.langText.input.fullName}}</span>
					</div>

					<!-- <div class="form__input form__input--status">
						<b :style="{ color: fields.type_direction_color }">{{
							fields.status
						}}</b>
						<span>{{$root.langText.input.status}}</span>
					</div> -->

					<Autocomplete
						:data="{
							name: $root.langText.input.source,
							mod: 'clientsSource',
							key: 'clientsSource',
							input: fields.clientsSource,
							inputId: fields.clientsSource_id,
						}"
						@result="autocompleteResult"
					/>

					<div class="form__input">
						<input type="text" v-model="fields.additionalSign" />
						<span>{{$root.langText.input.additionalSign}}</span>
					</div>

					<div
						class="form__checkbox"
					>
						<label>
							<input
								type="checkbox"
								v-model="fields.specialControl"
							/>
							<span>{{$root.langText.input.specialControl}}</span>
						</label>
					</div>

					<div class="form__checkbox">
						<label>
							<input
								type="checkbox"
								v-model="fields.blacklist"
							/>
							<span>{{$root.langText.input.blacklist}}</span>
						</label>
					</div>

					<Autocomplete
						:data="{
							name: $root.langText.input.city,
							mod: 'city',
							key: 'city',
							input: fields.city,
							inputId: fields.city_id,
						}"
						@result="autocompleteResult"
					/>

					<Autocomplete
						:data="{
							name: $root.langText.input.manager,
							mod: 'team',
							key: 'manager',
							input: fields.manager,
							inputId: fields.manager_id,
						}"
						@result="autocompleteResult"
					/>

					<Autocomplete
						:data="{
							name: $root.langText.input.documentManager,
							mod: 'team',
							key: 'documentManager',
							input: fields.documentManager,
							inputId: fields.documentManager_id,
						}"
						@result="autocompleteResult"
					/>

					<h2>{{$root.langText.input.titleTypeCompany}}</h2>
					<div class="form__row form__row-wrap">
						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.type.forwarder"
								/>
								<span>{{$root.langText.input.forwarder}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.type.producer"
								/>
								<span>{{$root.langText.input.producer}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.type.motorShow"
								/>
								<span>{{$root.langText.input.motorShow}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.type.broker"
								/>
								<span>{{$root.langText.input.broker}}</span>
							</label>
						</div>
					</div>

					<h2>{{$root.langText.input.titleDirection}}</h2>
					<div class="form__row form__row-wrap">
						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.direction.imports"
								/>
								<span>{{$root.langText.input.imports}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.direction.export"
								/>
								<span>{{$root.langText.input.export}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.direction.transit"
								/>
								<span>{{$root.langText.input.transit}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.direction.internal"
								/>
								<span>{{$root.langText.input.internal}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"

									v-model="fields.direction.doNotCarry"
								/>
								<span>{{$root.langText.input.doNotCarry}}</span>
							</label>
						</div>
					</div>

					<h2>{{$root.langText.input.titleService}}</h2>
					<div
						class="form__row form__row-wrap"
					>
						<div class="form__checkbox">
							<label>
								<input
									type="checkbox"

									v-model="fields.service.freight"
								/>
								<span>{{$root.langText.input.freight}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.auto"
								/>
								<span>{{$root.langText.input.auto}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.railway"
								/>
								<span>{{$root.langText.input.railway}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.aviation"
								/>
								<span>{{$root.langText.input.aviation}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.lcl"
								/>
								<span>{{$root.langText.input.lcl}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"
						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.teo"
								/>
								<span>{{$root.langText.input.teo}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"

						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.transshipment"
								/>
								<span>{{$root.langText.input.transshipment}}</span>
							</label>
						</div>

						<div
							class="form__checkbox"

						>
							<label>
								<input
									type="checkbox"
									:disabled="!$root.edit.can_edit_client"
									v-model="fields.service.customsCleaning"
								/>
								<span>{{$root.langText.input.customsCleaning}}</span>
							</label>
						</div>
					</div>

					<div class="form__input">
						<input type="text" v-model="fields.loads" />
						<span>{{$root.langText.input.loads}}</span>
					</div>
				</div>
			</div>

      <div v-if="!templateDeleteBtn">
        <!-- templatesUnloading -->
        <div v-if="data.modPopup == 'templatesUnloading'">
          <h2>№{{ +data.quantityList + 1 }}</h2>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressClearanceOut }}
            </p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="templates.addressClearanceOut"
            />
            <span>{{$root.langText.input.addressClearanceOut}}</span>
          </div>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressUnloading }}
            </p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="templates.addressUnloading"
            />
            <span>{{$root.langText.input.addressUnloading}}</span>
          </div>

          <Autocomplete
            :data="{
              name: $root.langText.input.unloadingPoint,
              mod: 'city',
              key: 'unloadingPoint',
              input: templates.unloadingPoint,
              inputId: templates.unloadingPoint_id,
              img: templates.unloadingPoint_img,
            }"
            @result="autocompleteResult"
          />
        </div>

        <!-- templatesDownload -->
        <div v-if="data.modPopup == 'templatesDownload'">
          <h2>№{{ +data.quantityList + 1 }}</h2>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressClearanceIn }}
            </p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="templates.addressClearanceIn"
            />
            <span>{{$root.langText.input.addressClearanceIn}}</span>
          </div>

          <div class="form__input">
            <p class="form__input__textarea-p min">
              {{ templates.addressDownload }}
            </p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="templates.addressDownload"
            />
            <span>{{$root.langText.input.addressDownload}}</span>
          </div>

          <Autocomplete
            :data="{
              name: $root.langText.input.downloadPoint,
              mod: 'city',
              key: 'downloadPoint',
              input: templates.downloadPoint,
              inputId: templates.downloadPoint_id,
              img: templates.downloadPoint_img,
            }"
            @result="autocompleteResult"
          />

          <div class="form__input">
            <input type="text" v-model="templates.cargo" />
            <span>{{$root.langText.input.cargo}}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="pop-up--delete">
          <div class="img warning"></div>
          <h3>{{$root.langText.infoDelete}}</h3>
        </div>
      </div>

      <p v-if="emty" style="color: red; padding: 0px 0 15px">{{$root.langText.changes.enterData}}</p>
      <div class="pop-up__btn">
        <a
          class="btn btn-secondary"
          @click.prevent="templateDelete"
          :style="
            (data.modPopup == 'templatesUnloading' ||
              data.modPopup == 'templatesDownload') &&
            data.mod == 'update'
              ? 'background:#EB5757'
              : 'opacity:0;visibility:hidden'
          "
          href="#"
          >{{$root.langText.delete}}</a
        >
        <a class="btn btn-primary" @click.prevent="save()">{{$root.langText.save}}</a>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
	.handbook_checkbox .form__row-wrap div span {
  	font-size: 13px;
	}
</style>



<script>
export default {
  props: ["data"],

  data() {
    return {
      //popup
      emty: false,
      templateDeleteBtn: false,
      car: {
        name: "",
        gov_number: "",
        year_manufacture: "",
        counterparties: "",
        counterparties_id: "",
        drivers: "",
        drivers_id: "",
        trailer: "",
        trailer_id: "",
      },
      trailers: {
        name: "",
        counterparties: "",
        counterparties_id: "",
        drivers: "",
        drivers_id: "",
      },
      drivers: {
        name: "",
        phone: "",
        comment: "",
        counterparties: "",
        counterparties_id: "",
        car: "",
        car_id: "",
        trailer: "",
        trailer_id: "",
      },
      counterparties: {
        name: "",
        people: "2",
      },
      templates: {
        addressClearanceOut: "",
        addressUnloading: "",
        unloadingPoint: "",
        unloadingPoint_id: "",
        unloadingPoint_img: "",

        addressClearanceIn: "",
        addressDownload: "",
        downloadPoint: "",
        downloadPoint_id: "",
        downloadPoint_img: "",
        cargo: "",
      },

			fields: {
				name: null,
				nameFull: null,
				type_direction_color: '#0286E5',
				clientsSource: '',
				clientsSource_id: '',
				additionalSign: null,
				specialControl: false,
				blacklist: false,
				cityImg: '/img/country/ua.png',
				city: null,
				city_id: null,
				address: null,
				site: null,
				manager: null,
				manager_id: null,
				documentManager: null,
				documentManager_id: null,
				type: {
					forwarder: false,
					producer: false,
					motorShow: false,
					broker: false,
				},
				direction: {
					imports: false,
					export: false,
					transit: false,
					internal: false,
					doNotCarry: false,
				},
				service: {
					freight: false,
					auto: false,
					railway: false,
					aviation: false,
					lcl: false,
					teo: false,
					transshipment: false,
					customsCleaning: false
				},
				loads: null
			},
    };
  },

  created() {

    if (this.data.contractor_id) {
      this.car.counterparties_id = this.data.contractor_id;
      this.trailers.counterparties_id = this.data.contractor_id;
      this.drivers.counterparties_id = this.data.contractor_id;
    }

    if (this.data.modPopup == "car" && this.data.mod == "update") {
      this.car.name = this.data.info.name;
      this.car.gov_number = this.data.info.gov_number;
      this.car.year_manufacture = this.data.info.year_manufacture;
      this.car.counterparties = this.data.info.contractor_name;
      this.car.counterparties_id = this.data.info.contractor_id;
      this.car.drivers = this.data.info.driver_name;
      this.car.drivers_id = this.data.info.driver_id;
      this.car.trailer = this.data.info.trailer_name;
      this.car.trailer_id = this.data.info.trailer_id;
    }
    if (this.data.modPopup == "trailers" && this.data.mod == "update") {
      this.trailers.name = this.data.info.name;
      this.trailers.counterparties = this.data.info.contractor_name;
      this.trailers.counterparties_id = this.data.info.contractor_id;
      this.trailers.drivers = this.data.info.driver_name;
      this.trailers.drivers_id = this.data.info.driver_id;
    }
    if (this.data.modPopup == "drivers" && this.data.mod == "update") {
      this.drivers.name = this.data.info.name;
      this.drivers.phone = this.data.info.phone;
      this.drivers.comment = this.data.info.comment;
      this.drivers.counterparties = this.data.info.contractor_name;
      this.drivers.counterparties_id = this.data.info.contractor_id;
      this.drivers.car = this.data.info.truck_name;
      this.drivers.car_id = this.data.info.truck_id;
      this.drivers.trailer = this.data.info.trailer;
      this.drivers.trailer_id = this.data.info.trailer_id;
    }
    if (
      this.data.modPopup == "templatesUnloading" &&
      this.data.mod == "update"
    ) {
      this.templates.addressClearanceOut = this.data.info.customs_address_to;
      this.templates.addressUnloading = this.data.info.unloading_address;
      this.templates.unloadingPoint = this.data.info.fd_name;
      this.templates.unloadingPoint_id = this.data.info.fd_id;
      this.templates.unloadingPoint_img = this.data.info.country_code
        ? this.data.info.country_code
        : "";
    }
    if (
      this.data.modPopup == "templatesDownload" &&
      this.data.mod == "update"
    ) {
      this.templates.addressClearanceIn = this.data.info.customs_address_from;
      this.templates.addressDownload = this.data.info.loading_address;
      this.templates.downloadPoint = this.data.info.poo_name;
      this.templates.downloadPoint_id = this.data.info.poo_id;
      this.templates.downloadPoint_img = this.data.info.country_code
        ? this.data.info.country_code
        : "";
      this.templates.cargo = this.data.info.cargo;
    }
  },

  methods: {
    autocompleteResult(data) {
      if (this.data.modPopup == "car") {
        this.car[data.input] = data.name;
        this.car[data.input + "_id"] = data.id;
      }
      if (this.data.modPopup == "trailers") {
        this.trailers[data.input] = data.name;
        this.trailers[data.input + "_id"] = data.id;
      }
      if (this.data.modPopup == "drivers") {
        this.drivers[data.input] = data.name;
        this.drivers[data.input + "_id"] = data.id;
      }
			if (this.data.modPopup == "clients") {
        this.fields[data.input] = data.name;
        this.fields[data.input + "_id"] = data.id;
				if(data.img){
					this.fields.cityImg = data.img
				}
      }
      if (
        this.data.modPopup == "templatesUnloading" ||
        this.data.modPopup == "templatesDownload"
      ) {
        this.templates[data.input] = data.name;
        this.templates[data.input + "_id"] = data.id;
      }
    },

    async templateDelete() {
      if (this.templateDeleteBtn) {
        if (this.data.modPopup == "templatesUnloading") {
          await this.$store.dispatch(
            "deleteTemplateUnloading",
            this.data.info.id
          );
          this.$emit("closePopup");
        }
        if (this.data.modPopup == "templatesDownload") {
          await this.$store.dispatch(
            "deleteTemplateLoading",
            this.data.info.id
          );
          this.$emit("closePopup");
        }
        this.templateDeleteBtn = false;
      } else {
        this.templateDeleteBtn = true;
      }
    },

    async save() {
      if (this.data.modPopup == "car") {
        var data = {
          name: this.car.name || "",
          gov_number: this.car.gov_number || "",
          year_manufacture: this.car.year_manufacture || "",
          contractor_id: this.car.counterparties_id || "",
          driver_id: this.car.drivers_id || "",
          trailer_id: this.car.trailer_id || "",
        };
        if (this.data.mod == "update") {
          data.id = this.data.info.id;
          await this.$store.dispatch("updateCar", data);
        } else {
          await this.$store.dispatch("addCar", data);
          this.$emit("closePopupCar", this.car.name);
        }
        this.$emit("closePopup");
      }

      if (this.data.modPopup == "trailers") {
        var data = {
          name: this.trailers.name || "",
          contractor_id: this.trailers.counterparties_id || "",
          driver_id: this.trailers.drivers_id || "",
        };
        if (this.data.mod == "update") {
          data.id = this.data.info.id;
          await this.$store.dispatch("updateTrailers", data);
        } else {
          await this.$store.dispatch("addTrailers", data);
        }
        this.$emit("closePopup");
      }

      if (this.data.modPopup == "drivers") {
        var data = {
          name: this.drivers.name || "",
          phone: this.drivers.phone || "",
          comment: this.drivers.comment || "",
          contractor_id: this.drivers.counterparties_id || "",
          truck_id: this.drivers.car_id || "",
          trailer_id: this.drivers.trailer_id || "",
        };
        if (this.drivers.name == "" || this.drivers.phone == "") {
          this.emty = true;
        } else if (this.data.mod == "update") {
          data.id = this.data.info.id;
          await this.$store.dispatch("updateDrivers", data);
          this.$emit("closePopup");
        } else {
          await this.$store.dispatch("addDrivers", data);
          this.$emit("closePopup");
        }
      }

      if (this.data.modPopup == "counterparties") {
        var data = {
          name: this.counterparties.name || "",
          entity_physical_person: this.counterparties.people || "",
          is_main_contractor: this.data.info.is_main_contractor || "",
          is_carrier: this.data.info.is_carrier || "",
          main_contractor: this.data.info.main_contractor || "",
          client_id: this.data.info.client_id || "",
        };
        if (this.data.mod == "update") {
          // data.id = this.data.info.id
          // await this.$store.dispatch('updateDrivers', data)
          // this.$emit('closePopup')
        } else {
          await this.$store.dispatch("createСounterparties", data);
          this.$emit("closePopup");
        }
      }

      if (this.data.modPopup == "templatesUnloading") {
        var data = {
          customs_address_to: this.templates.addressClearanceOut || "",
          unloading_address: this.templates.addressUnloading || "",
          fd_id: this.templates.unloadingPoint_id || "",
          client_id: this.data.idClient,
        };
        if (this.data.mod == "update") {
          data.id = this.data.info.id;
          await this.$store.dispatch("updateTemplateUnloading", data);
          this.$emit("closePopup");
        } else {
          await this.$store.dispatch("addTemplateUnloading", data);
          this.$emit("closePopup");
        }
      }
      if (this.data.modPopup == "templatesDownload") {
        var data = {
          customs_address_from: this.templates.addressClearanceIn || "",
          loading_address: this.templates.addressDownload || "",
          poo_id: this.templates.downloadPoint_id || "",
          cargo: this.templates.cargo || "",
          client_id: this.data.idClient,
        };
        if (this.data.mod == "update") {
          data.id = this.data.info.id;
          await this.$store.dispatch("updateTemplateLoading", data);
          this.$emit("closePopup");
        } else {
          await this.$store.dispatch("addTemplateLoading", data);
          this.$emit("closePopup");
        }
      }


			if(this.data.modPopup == "clients"){
				function boolean(val){
					return (val == false) ? 0 : 1
				}

				var data = {
					name: this.fields.name || '',
					full_name: this.fields.nameFull || '',
					source_group: this.fields.clientsSource_id,
					dop_priznak: this.fields.additionalSign || '',
					specialControl: boolean(this.fields.specialControl),
					blackList: boolean(this.fields.blacklist),
					city: this.fields.city_id || '',
					// this.fields.address = this.clientsId
					// this.fields.site = this.clientsId
					customer_manager: this.fields.manager_id || '',
					doc_support_manager_id: this.fields.documentManager_id || '',

					expeditor: boolean(this.fields.type.forwarder),
					proizvoditel: boolean(this.fields.type.producer),
					avtosalon: boolean(this.fields.type.motorShow),
					broker: boolean(this.fields.type.broker),

					import: boolean(this.fields.direction.imports),
					export: boolean(this.fields.direction.export),
					tranzit: boolean(this.fields.direction.transit),
					vnutrennie: boolean(this.fields.direction.internal),
					notWork: boolean(this.fields.direction.doNotCarry),

					fraht: boolean(this.fields.service.freight),
					avto: boolean(this.fields.service.auto),
					gd: boolean(this.fields.service.railway),
					avia: boolean(this.fields.service.aviation),
					lcl: boolean(this.fields.service.lcl),
					teo: boolean(this.fields.service.teo),
					perevalka: boolean(this.fields.service.transshipment),
					tamOchistka: boolean(this.fields.service.customsCleaning),

					cargo: this.fields.loads
				}
				var create = await this.$store.dispatch('createClients', data)
        this.$emit("closePopup");
			}
    },
  },

  components: {
    Autocomplete: () => import("@/components/app/Autocomplete.vue"),
  },
};
</script>