import axios from 'axios'

export default {
  actions: {

    async getServices({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/services/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersServices({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/services/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  },  
}