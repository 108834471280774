import axios from 'axios'

export default {
  actions: {

    async getCounterparties({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/contractors/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

    async getСounterpartiesId({commit}, idСounterparties) {
      var result;
      try {
        return await axios
          .get("/api/contractors/get/" + idСounterparties + "/", {
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },






    async postFiltersСounterparties({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/contractors/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },




    async updateСounterparties({commit}, updateСounterparties) {
      var result;
      try {
        return await axios
          ('/api/contractors/update/', {
            
            method: 'POST',
            data: updateСounterparties
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },





    async createСounterparties({commit}, createСounterparties) {
      var result;
      try {
        return await axios
          ('/api/contractors/add/', {
            
            method: 'POST',
            data: createСounterparties
          })
          .then(resp => {
            // console.log(resp)
            return resp
            // resolve(resp)
          })
          .catch(err => {
            // console.log(err)
            return err
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },









    async getCounterpartiesGroup({commit}, { start, limit }) {
      var result;
      try {
        return await axios
          .get('/api/contractors_groups/list/?start=' + start + '&num=' + limit, {
            
            
          })
          .then(response => (result = response))
          .catch(error => console.log(error));
      } catch (e) {
          console.log(e);
          throw e;
      }
    },


    async postFiltersСounterpartiesGroup({commit}, {params: {start, limit}, data}) {
      try {
        return await axios
          ('/api/contractors_groups/list/?start=' + start + '&num=' + limit, {
            
            method: 'POST',
            data: {filters: data}
          })
          .then(resp => {
            return resp
            // resolve(resp)
          })
          .catch(err => {
            console.log(err)
            // reject(err)
          })
      } catch (e) {
          console.log(e);
          throw e;
      }
    },

  }
}